.custom-file-upload {
    /* border: 1px solid #ccc; */
    /* background-color: rgba(152, 119, 189, 1); */
    width:100px;
    height:100px;
    border-radius: 5px;
    border:2px solid  rgba(60, 64, 67, 0.15);
    color: #fff;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;
  }

  .custom-file-input{
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 134px;
    height: 134px;
    z-index: 1;
  }
  .position-text{
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 5px 15px;
    color: #263238;
    padding: 6px 16px;
    font-size: 12px;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    /* font-weight: 500; */
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    width: 64px;
  }